export const navLinks = [
  // {
  //   label: "Hurricane Experimental Forecast",
  //   path: "/hurricane",
  //   sublinks: [],
  // },
  // {
  //   label: "Hurricane Debby",
  //   path: "/hurricanedebby",
  //   sublinks: [],
  // },
  // {
  //   label: "Paris Olympic RDP",
  //   path: "/ParisOlympics",
  //   sublinks: [],
  // },
  // {
  //   label: "HOME",
  //   path: "/",
  //   sublinks: [],
  // },
  {
    label: "Research",
    path: "/projects/OngoingProjects",
    sublinks: [],
  },
  {
    label: "News",
    path: "/News",
    sublinks: [],
  },
  {
    label: "Our Team",
    path: "/Team",
    sublinks: [],
  },
  {
    label: "Publications",
    path: "https://scholar.google.com/citations?hl=en&user=Ixl0-pAAAAAJ&view_op=list_works&sortby=pubdate",
    sublinks: [],
  },
  // {
  //   label: "JOIN US",
  //   path: "/join",
  //   sublinks: [],
  // },
];