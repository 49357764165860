import React from 'react';
import './PeopleDetails.css';
import People from './pages/People';

const PeopleDetails = ({ person, peopleProjects }) => {
  if (!person) {
    return <div>Person not found.</div>;
  }

  const formattedName = person.name.replace(/\s+/g, '_').toLowerCase();

  console.log('peopleProjects:', peopleProjects[formattedName]);


  return (
    <div className="main-page">
      <div className="peopledetailbox">
  <img src={person.image} alt={person.name} />
  <h2>{person.name}</h2>
  <p>{person.title}</p>
  <div className="social-links">
    {person.linkedin && (
      <a href={person.linkedin} target="_blank" rel="noopener noreferrer">
        <i className="fa-brands fa-linkedin"></i>
      </a>
    )}
    {person.website && (
      <a href={person.website} target="_blank" rel="noopener noreferrer">
        <i className="fa-solid fa-globe"></i>
        </a>
    )}
     {person.email && (
    <a href={`mailto:${person.email}`} target="_blank" rel="noopener noreferrer">
      <i className="fa-solid fa-envelope"></i>
    </a>
  )}
  </div>
</div>
<div className="associated-projects">
  <h2>{person.bio}</h2>
  {peopleProjects[formattedName] && (
    <>
    <div class="current-projects">
    <h3>Projects:</h3>
    <ul className="projects-content">
  {peopleProjects[formattedName].map((project) => (
    <li key={project.targetSection} className="project-card">
      <img src={process.env.PUBLIC_URL + "/images/" +  project.pictureSrc} alt={project.title} className="project-image" />
      <a href={`/content/${project.targetSection}`} className="project-link">
          <h4>{project.title}</h4>
        </a>
    </li>
  ))}
</ul>
    </div>
    </>
  )}
</div>
    </div>
  );
};

export default PeopleDetails;