import React from 'react';
import { Link } from 'react-router-dom';

function NewsCard({ title, date, linkTo }) {
  const [fullMonth, dayWithComma] = date.split(' ');
  const day = dayWithComma.replace(',', '').replace(/\D/g, '');
  const month = fullMonth.substring(0, 3);

  return (
    <div className="news-card">
      <div className="news-date">
        <div className ="news-month">{month} </div>
        <div className ="news-day">{day} </div>
      </div>
      <div className="news-content">
         <Link to={'/News'} className = "links">{title}</Link>

      </div>
    </div>
  );
}

export default NewsCard;