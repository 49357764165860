import React from 'react';
import './FeaturedProjects.css'; // CSS for styling the component

const FeaturedProjects = () => {
    const projects = [
        { title: 'Paris 2024 Olympics RDP', content: 'Paris 2024 Olympics Experimental Weather Forecasts.',link:'/ParisOlympics' },
        { title: 'Experimental Forecasts from UT', content: 'Hurricane Experimental Forecasts with TACC',link:'/hurricane' },
        { title: 'Cool Pavement Project', content: 'Exploring cool pavement to reduce heat and lower surface temperatures.',link:'/content/cool-pavement' }
    ];

    return (
        <section className="feature-project">
            <h2 className="feature-heading">Featured Projects</h2>
            <div className="featuredcards-container">
                {projects.map((project, index) => (
                     <a href={project.link} className="featuredcard" key={index}>
                     <div className="blue-rectangle">
                         <div className="icon-wrapper">
                             <i className="fa-solid fa-arrow-right"></i>
                         </div>
                     </div>
                     <h3 className="card-title">{project.title}</h3>
                     <p className="featuredcard-content">{project.content}</p>
                 </a>
                ))}
            </div>
        </section>
    );
};

export default FeaturedProjects;