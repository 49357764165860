import React from "react";
import "./Join.css";

function Join() {
  return (
    <div>

    <br/> <h1 className="join-header" id="titleroundedbox">Join Our Team!</h1>
    <div className="join-container">
      <div className="content">
        <div class="headerbox">   
        </div>
        <p className="join-subheader">
          We are always looking for promising students! <br /><br/>
          Undergraduate / Graduate / Post-Doc students are welcome. If interested, please send an email to <a href="mailto:happy1@utexas.edu">happy1@utexas.edu</a></p><br/>
          
          <p className="join-subheader info" >
            <p>Please include the following in your email:</p><br/>
            <ul class="contentlist">
              <li>Year of Study</li>
              <li>Degree program</li>
              <li>CV/Resume</li>
              <li>What you are looking for (Research, Potential Grad Student, For credit undergrad topic etc.)</li>
              </ul>
              </p>
              </div>
              
    <div className="img-container">
      <img
        src="https://texuslab.org/images/Umbrella.jpeg"
        alt="Promising Students"
        className="header-image"
      />
      </div>
   </div>
  </div>
  );
}


export default Join;
