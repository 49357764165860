import React from 'react';
import NewsCard from './NewsCard';
import newsData from './pages/NewsInfo'; // Change import to NewsInfo

function NewsSection() {
  const reversedNewsData = [...newsData].reverse(); // Use newsData instead of NewsInfo

  return (
    <div className="news-section">
      {reversedNewsData.map((newsItem, index) => {
        // Convert date to month and day
        const date = new Date(newsItem.date);
        const formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });

        return (
          <React.Fragment key={index}>
            <NewsCard
              title={newsItem.title} // Use title from newsInfo
              date={newsItem.date}   // Use formatted date
              content={newsItem.content}
              link={newsItem.link}   // Use link from newsInfo
            />
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default NewsSection;