import React from 'react';
import './ContentPage.css'; // Import the CSS file
import { Link } from 'react-router-dom'; 
import { teamMates } from './teamData'; // Import teamMates data

function ContentPage({ project }) {
  if (!project) {
    return <div>Project not found.</div>;
  }

  const collaborators = project.Collaborators.split(',').map((name) => name.trim());

  return (
    <div className="content-page">
        <img src= {process.env.PUBLIC_URL + "/images/" +  project.pictureSrc} alt={project.title} class="contentmain-img"/>
        <div className="content-text">
             <h2>{project.title}</h2>
             <div class="collabcard">
   {
   collaborators.map((collaborator, index) => {

     // Split the collaborator's name by whitespace
     const [firstName, lastName] = collaborator.split(' ');

     // Create the link using firstName and lastName
     const linkTo = `/Team/${firstName}_${lastName}`;           

     // Find the corresponding team member
     const teamMember = teamMates.find(member => member.name === collaborator);

     // Use the team member's image if found, otherwise use a default image
     const imageSrc = teamMember ? teamMember.image : process.env.PUBLIC_URL + "/images/defaultpic.png";

     return (
       <><div class="collabcard-content">
         <img src={imageSrc} alt={project.title} />
         <Link key={index} to={linkTo}>
         {collaborator}
       </Link>  
       </div>
         </>
     );
   })}
   </div>
    <h2>{project.title2}</h2>
    <p className = "Description">{project.objective}</p><br/>
    <h2>{project.title3}</h2>
    <p className = "Description">{project.longContent1}</p><br/>
    <p className = "Description">{project.longContent2}</p><br/>
    <p className = "Description">{project.longContent3}</p><br/>
    </div>
    </div>

);
}

export default ContentPage;