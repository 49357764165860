import React, { useState }from 'react';
import './ParisRDP.css'; // Create a new CSS file for Paris styling.
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function ParisOlympic11Aug() {
  const [value, onChange] = useState(new Date());
  const animationresponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1
    }
  };

  const onChangeDate = (date) => {
    const checkDate = new Date(date);
    const stringDate = checkDate.toString();

    if(stringDate.includes("Jul 26") == true){
      //archiveurl
      window.open('/ParisOlympics26Jul', '_blank');
    }

    if(stringDate.includes("Jul 29") == true){
      //archiveurl
      window.open('/ParisOlympics29Jul', '_blank');
    }
    if(stringDate.includes("Jul 30") == true){
      //archiveurl
      window.open('/ParisOlympics30Jul', '_blank');
    }
    if(stringDate.includes("Jul 31") == true){
      //archiveurl
      window.open('/ParisOlympics31Jul', '_blank');
    }
    if(stringDate.includes("Aug 01") == true){
      //archiveurl
      window.open('/ParisOlympics01Aug', '_blank');
    }
    if(stringDate.includes("Aug 02") == true){
      //archiveurl
      window.open('/ParisOlympics02Aug', '_blank');
    }
    if(stringDate.includes("Aug 03") == true){
      //archiveurl
      window.open('/ParisOlympics03Aug', '_blank');
  }
  if(stringDate.includes("Aug 04") == true){
    //archiveurl
    window.open('/ParisOlympics04Aug', '_blank');
}
if(stringDate.includes("Aug 05") == true){
  //archiveurl
  window.open('/ParisOlympics05Aug', '_blank');
}
if(stringDate.includes("Aug 06") == true){
  //archiveurl
  window.open('/ParisOlympics06Aug', '_blank');
}
if(stringDate.includes("Aug 07") == true){
  //archiveurl
  window.open('/ParisOlympics07Aug', '_blank');
}
if(stringDate.includes("Aug 08") == true){
  //archiveurl
  window.open('/ParisOlympics08Aug', '_blank');
}
if(stringDate.includes("Aug 09") == true){
  //archiveurl
  window.open('/ParisOlympics09Aug', '_blank');
}
if(stringDate.includes("Aug 10") == true){
  //archiveurl
  window.open('/ParisOlympics10Aug', '_blank');
}
if(stringDate.includes("Aug 28") == true){
  //archiveurl
  window.open('/ParisOlympics28Aug', '_blank');
}
  };

  return (
    <div className="main">
       <div className="parisheader">
    <img src="images/parisbackround.png" alt="Background" className="paris-background" />

    <div className="header-container">
        <div className="title-content">
            <div className="title-logo">
                <img src="images/parislogo.png" alt="Paris Olympic logo" />
                <h1>Paris Olympics RDP</h1>
            </div>
            <div className="heading-text">
                <h2>UT Meteogan Downscaling in partnership with Texas Advanced Computing Center (TACC) as part of World Meteorological Organization (WMO) Remote Desktop Protocol (RDP)</h2><br></br>
                <div className="button-row">
                    <a href="https://www.linkedin.com/posts/research-at-the-university-of-texas-at-austin_olympics-meteorological-paris2024-activity-7222594602212454401-mCF-?utm_source=share&utm_medium=member_android" className="social-button">
                    <img src="/images/linkedin-icon.avif" alt="LinkedIn" className="icon" /> 
                        <span className="underline-text">Read UT Research Announcement</span>
                        <i class="fa-solid fa-arrow-up-right-from-square" style={{color: '#ffffff', marginLeft:'10px'}}></i>
                    </a>
                    <a href="https://wmo.int/media/news/paris-olympics-will-advance-research-weather-forecasting-and-urban-meteorology" target="_blank" rel="noopener noreferrer" className="social-button">
                        <img src="/images/wmo-icon.jpg" alt="External Link" className="icon" /> 
                        <span className="underline-text">Read WMO Article</span>
                        <i class="fa-solid fa-arrow-up-right-from-square" style={{color: '#ffffff', marginLeft:'10px'}}></i>
                    </a>
                </div>
            </div>
        </div>

        <div className="calendargrid">
            <h1 style={{fontSize: '1.5rem',textAlign:'center', paddingBottom:'10px'}}>Forecast Calendar</h1>
            <Calendar
                onChange={onChangeDate}
                value={value}
                minDate={new Date('July 26, 2024 23:15:30')}               
            />
        </div>
    </div>

    <div className="footer-content">
        <p><strong>Experimental Forecasts from UT Austin - Jackson School of Geosciences/ TExUS Lab</strong></p>
        <p>This is an experimental/research model study. The model runs are on TACC compute resources at UT. This experimental forecast is not for public advisory or use, or any decision making and is only updated here for the lab and project teams to test the models. No liability is assumed. The official forecasts are available from NOAA/NWS <a href="https://www.weather.gov/ewx/" target="_blank" rel="noopener noreferrer">https://www.weather.gov/ewx/</a> and <a href="https://www.nhc.noaa.gov/" target="_blank" rel="noopener noreferrer">https://www.nhc.noaa.gov/</a>.</p>
        <br></br><p><a href="https://wmo.int/media/news/paris-olympics-will-advance-research-weather-forecasting-and-urban-meteorology" target="_blank" rel="noopener noreferrer">Image Source: WMO News</a></p>
    </div>
</div>
     
      <div class="graph-title">
        <h1>Observed Precipitation from Radar</h1>
        </div>
        <Carousel responsive={animationresponsive}>
      <div class="graph-box">
        <h2>1 Aug 2024</h2>
        <p>02:15-04:15</p>
        <div class="video-container">
            <video width="300px" height="400px" controls="controls">
                <source src="/videos/Forecastrecording_1aug_2-4.mov" />
            </video>
        </div>
      </div>
      <div class="graph-box">
      <h2>1 Aug 2024</h2>
      <p>06:20-08:20</p>
        <div class="video-container">
            <video width="300px" height="400px" controls="controls">
              <source src="/videos/Forecastrecording_1aug_6-8.mov" />
            </video>
        </div>
      </div>
      <div class="graph-box">
      <h2>2 Aug 2024</h2>
      <p>14:00-16:00</p>
      <div class="video-container">
      <video width="300px" height="400px" controls="controls">
        <source src="/videos/Forecastrecording_2aug_14-16.mov" />
    </video>
      </div>
      </div>
      <div class="graph-box">
      <h2>2 Aug 2024</h2>
      <p>18:20-20:20</p>
      <div class="video-container">
      <video width="300px" height="400px" controls="controls">
        <source src="/videos/Forecastrecording_2aug_18-20.mov" />
     </video>
      </div>
      </div>
      <div class="graph-box">
      <h2>2 Aug 2024</h2>
      <p>22:30-00:30</p>
      <div class="video-container">
      <video width="300px" height="400px" controls="controls">
        <source src="/videos/Forecastrecording_2aug_22.mov" />
    </video>
      </div>
      </div>
      <div class="graph-box">
      <h2>2 Aug 2024</h2>
      <p>01:55-03:50</p>
      <div class="video-container">
      <video width="300px" height="400px" controls="controls">
        <source src="/videos/Forecastrecording_2aug_1-3.mov" />
    </video>
      </div>
      </div>
      <div class="graph-box">
      <h2>3 Aug 2024</h2>
      <p>07:10-09:10</p>
      <div class="video-container">
      <video width="300px" height="400px" controls="controls">
        <source src="/videos/Forecastrecording_3aug_7-9.mov" />
    </video>
      </div>
      </div>
      <div class="graph-box">
      <h2>3 Aug 2024</h2>
      <p>14:30-14:30</p>
      <video width="300px" height="400px" controls="controls">
        <source src="/videos/Forecastrecording_3aug_14-16.mov" />
    </video>
      </div>
      <div class="graph-box">
      <h2>4 Aug 2024</h2>
      <p>03:45-05:45</p>
      <div class="video-container">
      <video width="300px" height="400px" controls="controls">
        <source src="/videos/Forecastrecording_4aug_3-6.mov" />
    </video>
      </div>
      </div>
      <div class="graph-box">
      <h2>5 Aug 2024</h2>
      <p>23:15-01:15</p>
      <div class="video-container">
      <video width="300px" height="400px" controls="controls">
        <source src="/videos/Forecastrecording_6aug_23-1.mov" />
    </video>
      </div>
      </div>
      <div class="graph-box">
      <h2>6 Aug 2024</h2>
      <p>23:45-01:45</p>
      <div class="video-container">
      <video width="300px" height="400px" controls="controls">
        <source src="/videos/Forecastrecording_6aug_23_2.mov" />
    </video>
      </div>
      </div>
      </Carousel>

      <div class="border-line"></div>
     
      <div class="forecast-title">
        <h1>Model Forecast starting 11 Aug 2024 00 UTC</h1>
        <h2>Precipitation Forecast</h2>
      <p>Dry condition are expected to continue today over Paris. Some rainfall is anticipated to the NW of France as there is a low pressure system with a cold from that has formed to the west of the United Kingdom. </p>
      </div>
      
      <div className="graphs-container">

      <img src="/images/mean_surf_prec_11aug.png" alt="total surface 31 Jul" />  
      <img src="/images/total_surf_precp_24hrs_11aug.png" alt="Mean surface 31Jul" />
      <img src="/images/total_surf_prec_36hrs_11aug.png" alt="total surface 31 Jul" />
      <img src="/images/total_surf_precp_11aug.gif" alt="total surface 31 Jul" />
      </div>

      <div class="forecast-title">
      <h2>Surface Temperature Forecast</h2>
      <p>Hotter temperatures are expected today with a high around 31˚C and a low of 22˚C, with an average of 26˚C. These warmer temperatures can be expected due to the dry conditions and sunny skies. </p>
      </div>

      <div className="graphs-container">
        <img src="/images/mean_surf_air_temp_11aug.png" alt="Average Surface Temperature" />
        <img src="/images/avg_surf_air_temp_24hrs_11aug.png" alt="Average Surface Temperature" />
        <img src="/images/avg_surf_air_temp_36hrs_11aug.png" alt="Average Surface Temperature" />
        <img src="/images/surf_temp_11aug.gif" alt="Average Surface Temperature" /> 
      </div>

      <div class="forecast-title">
      <h2>Wind Forecast</h2>
      <p>Winds remain to be mild with maximum surface wind speeds around 12 km/h from the NE. Winds in the northern part of France are expected increase due to the cold front that is moving in. </p>
      </div>
      <div className="graphs-container">
      <img src="/images/mean_surf_winds_11aug.png" alt="Winds 2024-07-31" />
      <img src="/images/mean_winds_24hrs_11aug.png" alt="Mean Winds 31 July" />
      <img src="/images/mean_winds_36hrs_11aug.png" alt="Mean Winds 31 July" />
      <img src="/images/winds_11aug.gif" alt="Mean Winds 31 July" />
      </div>

      <div className="meteoganimage-container">
      <h1>UT-MeteoGAN details</h1>
        <p>Click below to view the MeteoGan Schematic</p>
        <a href="images/ut_meteogan_schematic.png"> <button className="parisbutton">MeteoGAN Schematic</button></a>
      </div>
 

      <div class="thermscale-box">
      <h1>Demo: Universal Thermal Climate Index for Paris</h1>
      <p> <bold>6 August 2024, 11 AM</bold></p>
      <br/>
      <iframe src="Paris_thermscale.html" width="100%" height="720"></iframe>
      </div>

      <div className="thermalscape-vid">
        <h1>Video of Universal Thermal Climate Index along Olympic marathon route </h1>
        <video loop autoPlay playsInline>
        <source src={process.env.PUBLIC_URL + '/videos/marathon-vid.mp4'} />
        </video>
      </div>

      <div class="thermscale-box">
        <h1>Demo: UTCI Along The Olympics Marathon Path</h1><br/>
      <iframe width="100%" height="720" frameborder="0" scrolling="no" allowfullscreen src="https://ut-austin.maps.arcgis.com/apps/instant/3dviewer/index.html?appid=0624bfad92da47ffaa3e74b5689aacbc"></iframe>

      </div>

      <div className="thermalscape-img">
        <h1>UTCI plotted for running along the marathon track </h1>
        <img src="/images/graph-marathon.png"/>
      </div>
      
    <footer>
        <p>&copy; 2024 University of Texas at Austin, Jackson School of Geosciences. All rights reserved.</p>
    </footer>
    </div>
  );
}

export default ParisOlympic11Aug;
