import React from 'react';
import './People.css';
import {teamMates } from '../teamData';
import { Link } from 'react-router-dom'; 


const People = () => {

  const renderTeamMembers = (members) => {
    return members.map((member, index) => {
      // Split the name into first and last name
      const fullName = member.name.trim();
      const [firstName, lastName] = fullName.split(' ');

      // Create a link based on the first and last name
      const linkTo = `/Team/${firstName}_${lastName}`; // Adjust the URL format as needed

      return (
          <div className="team-member">
             <Link key={index} to={linkTo}>
            <img src={member.image} alt={`Member ${index}`} />
            <p>{fullName}</p>
            {member.title && <p>{member.title}</p>}
            </Link>
          </div>
      );
    });
  };

  return (
      <div>
      <div className="header">
        <div className="wall-container">
          <video className="bg-video" autoPlay muted loop  playsInline>
            <source src={process.env.PUBLIC_URL + '/videos/Austin-2.mp4'} />
            </video>
      </div>
      <h1>Meet the Team</h1>
      </div>

      <div className="meet-the-team">
      <section className="team-section">
      
      <div className="team-members">
      {renderTeamMembers(teamMates)}
      </div>
      
      </section>
      </div>
      
      </div>      
      );     
      };     

export default People;